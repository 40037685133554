import * as React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { links } from "../components/Nav";
import BackgroundImg from "gatsby-background-image";
import SEO from "../components/SEO";
import { FaFacebook, FaInstagram, FaGoogle } from "react-icons/fa";

export const query = graphql`
  query HomeQuery {
    sanitySiteSettings {
      id
      headline
      subheader
      featuredImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

const variants = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -150 },
};

// markup
function Home({ data }) {
  const { featuredImage, subheader } = data.sanitySiteSettings;
  const { base64, ...image } = featuredImage.asset.fluid;
  console.log(image);

  return (
    <>
      <SEO
        title="CLIPS Hair Studio | Let's Make You Look Fantastic!"
        description="CLIPS Hair Studio is a hair salon in West Little Rock offering Color,
        Keratin and more.  Book your appointment today, and let's make you look fantastic!"
      />

      <Hero
        Tag="section"
        critical={true}
        fadeIn={false}
        fluid={image}
        backgroundColor={`#fffffff`}
      >
        <Welcome
          transition={{ delay: 0.125 }}
          initial={false}
          animate={{ x: 0 }}
          variants={variants}
        >
          <WelcomeMessage>
            Welcome to
            <br /> CLIPS Hair Studio!
          </WelcomeMessage>
          <SubMessage>{subheader}</SubMessage>
          <WelcomeButton to="/book-online">Book Your Appointment</WelcomeButton>
        </Welcome>
        <Footer>
          <FooterContent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <a
              href="https://www.facebook.com/clipshairstudiolr"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size="1.625em" style={{ marginLeft: ".625rem" }} />
            </a>
            <a
              href="https://www.instagram.com/clipshairstudio/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size="1.625em" style={{ marginLeft: ".625rem" }} />
            </a>
            <a
              href="https://g.page/clipshairstudio?gm"
              target="_blank"
              rel="noreferrer"
            >
              <FaGoogle size="1.625em" style={{ marginLeft: ".625rem" }} />
            </a>
            <br />
            ©2021 CLIPS Hair Studio, All Rights Reserved
            <br />
            {links.map((link, i) => {
              if (link.footer) {
                return (
                  <Link key={link.slug} to={link.slug}>
                    {link.name}
                    {links[i + 1] ? " | " : ""}
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </FooterContent>
        </Footer>
      </Hero>
    </>
  );
}

export default Home;

const Hero = styled(BackgroundImg)`
  background-color: rebeccapurple;
  position: relative;
  top: var(--nav-height);
  min-height: calc(100vh - var(--nav-height));
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Welcome = styled(motion.div)`
  position: relative;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 5vw 0 5vw;
  @media screen and (min-width: 992px) {
    align-items: flex-start;
  }
`;

const WelcomeButton = styled(Link)`
  text-decoration: none;
  background: none;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 1.625rem;
  font-weight: 300;
  border-radius: 4px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  outline: none;
  padding: 0.5em 0.75em 0.5em 0.75em;
  max-width: 25ch;
  justify-self: center;
  margin-top: 3rem;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(255, 255, 255, 0.5);
  :hover {
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: calc(36px + (72 - 44) * ((100vw - 300px) / (1920 - 300)));
  line-height: calc(42px + (72 - 48) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 300;
  font-family: "Raleway", sans-serif;
  color: #fff;
  margin-top: 10vw;
  text-align: center;
  margin: 0 0 1rem 0;
  @media screen and (min-width: 960px) {
    text-align: left;
  }
`;

const SubMessage = styled.h2`
  font-size: calc(24px + (64 - 44) * ((100vw - 300px) / (1920 - 300)));
  line-height: calc(28px + (64 - 48) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 100;
  font-family: "Raleway", sans-serif;
  color: #fff;
  margin-top: 10vw;
  text-align: center;
  margin: 0;
`;

const Footer = styled.footer`
  padding: 0.5rem 2em;
  opacity: 0.8;
  color: white;
  justify-content: center;
  a {
    color: white;
  }

  @media screen and (min-width: 960px) {
    text-align: right;
  }
`;

const FooterContent = styled(motion.p)`
  display: block;
  max-width: 100%;
  text-align: center;
  font-size: 0.75rem;
  @media screen and (min-width: 960px) {
    text-align: right;
    font-size: 1rem;
  }
`;
